import UserStore from './account/user';
import TeamStore from './team/index';
import BrowserStore from './browser/logger';
import SiteStore from './location/index';
import ImageDrawBoxStore from './markdata/imagedrawbox';
import ImageGlobalStore from './markdata/imageglobal';
import AudioABX from './markdata/audioabx';
import VideoMarkStore from './markdata/videomark';
import LowCode from './markdata/lowcode';
import QuickRender from './markdata/quickRender';

const stores = {
  user: new UserStore(),
  team: new TeamStore(),
  browser: new BrowserStore(),
  imagedrawbox: new ImageDrawBoxStore(),
  imageglobal: new ImageGlobalStore(),
  audioabx: new AudioABX(),
  videoMark: new VideoMarkStore(),
  site: new SiteStore(),
  lowCode: new LowCode(),
  quickRender: new QuickRender()
};
export default stores;
