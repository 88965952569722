export const allowApiList = [
  '/rbac/captcha',
  '/rbac/login',
  '/rbac/appLogin',
  '/getSMSCode',
  '/register',
  '/rbac/get1V1UserInfo',
  '/rbac/getSatisfaction',
  '/rbac/exportSatisfaction',
];

export const allowPageList = [
  '/login',
  '/login/verification',
  '/result/no-data',
  '/result/no-authority',
  '/result/no-match',
  '/register',
  '/result/register/:roleType?/:groupCode?',
  '/questionnaire/result',
  // 移动端切换登录
  '/other-mark/low-code-template/:taskId/:subMarkType?'
];
