// 文本编辑器组件预设示例
export const TEXT_EDITOR_DEMO = [
  {
    name: '段落',
    children: [
      {
        // name: '小节1',
        value:
          '关于x的方程2x$$^{2}$$+mx-n=0的两根是-1和3，则2x$$^{2}$$+mx-n因式分解的结果是（）\nA.(x+1)(x-3)\nB.2(x+1)(x-3)\nC.(x-1)(x+3)\nD.2(x-1)(x+3)',
        originValue:
          // eslint-disable-next-line max-len
          '<p>关于x的方程2x<script type="math/tex">^{2}</script>+mx-n=0的两根是-1和3，则2x<script type="math/tex">^{2}</script>+mx-n因式分解的结果是（）</p><p>A.(x+1)(x-3)</p><p>B.2(x+1)(x-3)</p><p>C.(x-1)(x+3)</p><p>D.2(x-1)(x+3)</p>'
        // id: '1179c332-a323-4466-8d6f-de35a2389b19'
      }
    ]
  }
];

// 单选组件预设示例
export const RADIO_DEMO = {
  value: '一级选项值',
  children: '二级选项值'
};

// 多选组件预设示例
export const CHECKBOX_DEMO = ['选项一', '选项二'];

// 文本划选组件预设示例
export const TEXT_SELECTION_DEMO = {
  key: 'e932265e-5608-46a6-8848-2af7adef6e5e',
  type: 'textSelection',
  name: '文本标注组件_0',
  value: [
    {
      label: '文本',
      text: '这是解题的关键',
      index: '75',
      selectKey: 'c34d28e5-5a2b-49e4-bfb9-235e665133f9',
      position: ['0-75-75', '0-81-81'],
      options: {
        文本内容是否有无: {
          opt: {
            key: '0dcf845c-7713-48b9-b295-85a844ccd585',
            isRequired: 1,
            title: '文本内容是否有无',
            type: 'radio',
            options: ['是', '否']
          },
          value: '是'
        },
        文本属于: {
          opt: {
            key: '27c41622-bdfd-456c-9853-44e3d44b788c',
            isRequired: 1,
            title: '文本属于',
            type: 'checkbox',
            options: ['旁白', '题解', '答案', '内容']
          },
          value: ['题解', '答案']
        },
        备注: {
          opt: {
            key: '3f8514fd-d70b-4238-8159-ecbd66615ddf',
            isRequired: 0,
            title: '备注',
            type: 'input',
            forbidNumber: 0
          },
          value: ''
        }
      }
    }
  ]
};

export const DRAW_GRAPH_DEMO = [
  {
    markGraph: {
      type: 'rect',
      points: [
        {
          x: -342,
          y: -858
        },
        {
          x: -544,
          y: -858
        },
        {
          x: -544,
          y: -800
        },
        {
          x: -342,
          y: -800
        }
      ],
      id: '69f5d0c3-b864-48a4-b3f2-33c5d495c596',
      typeText: '矩形',
      index: 20,
      isCustom: true
    },
    markLabel: {
      type: '0',
      value: {
        color: '#8322ff',
        text: '整题',
        order: 1,
        value: '整题',
        closable: false
      }
    },
    markAttrList: [
      {
        id: 'attr-0e4e3b25-7f08-4201-9452-6d57a483ed1c',
        type: 'radio',
        name: '题目类别',
        label: '题目类别',
        value: '可批改题型',
        customizeKind: 1
      },
      {
        id: 'attr-622955c7-a327-4416-b89a-67b312814507',
        type: 'radio',
        name: '题型',
        label: '题型',
        value: '填空题',
        customizeKind: 1
      }
    ],
    originPosition: 'center'
  }
];

export default {};
