import { mergeDifferences } from '@/low_code/utils/tools';
import { observable, action, toJS } from 'mobx';

export default class LowCodeStore {
  @observable markData: any = {
    allOptList: {},
    complexSearch: new Map(),
    testAllOptList: new Map(),
    tempAllOptList: new Map(),
    searchQueryMap: new Map(),
    isExpand: true
  };

  // 这个数据中存储映射值，存储的是预览或标注等地方修改的内容，坐标，key，用于最后生成输出信息时使用
  @observable previewMarkData: any = [];

  // 回显数据映射表，这个数据存储服务器返回的已标注的内容，在各个组件中进行内容回显
  @observable EchoMappingData: any = [];

  // 存储图像标框组件实例，用于提交时根据实例获取缩略图,数据结构为对象{key:'',value:object}，key值确认实例，value存储内容
  @observable ImgMarkData: any = [];

  @action
  updateFilterOptionsList(key: string, value: any): void {
    this.markData.filterOptionsList.set(key, value);
  }

  @action
  updateMarkData(markData: any): void {
    this.markData = {
      ...this.markData,
      ...markData
    };
  }

  @action
  updateComplexSearch(idx: number, list: any): void {
    this.markData.complexSearch.set(idx, list);
  }

  @action
  updateTestAllOptList(key: string, value: any): void {
    this.markData.testAllOptList.set(key, value);
  }

  @action
  updateSearchQueryMap(key: string, value: any): void {
    this.markData.searchQueryMap.set(key, value);
  }

  @action
  removeComplexSearch(idx: number): void {
    this.markData.complexSearch.delete(idx);
  }

  @action
  removeSearchQueryMap(key: string): void {
    this.markData.searchQueryMap.delete(key);
  }

  @action
  getComplexSearch(key = ''): void {
    if (key) {
      return this.markData.complexSearch.get(key);
    }
    return this.markData.complexSearch;
  }

  @action
  getTestAllOptList(key = ''): any {
    if (key) {
      return this.markData.testAllOptList.get(key);
    }
    return this.markData.testAllOptList;
  }

  @action
  getSearchQueryMap(key = ''): any {
    if (key) {
      return this.markData.searchQueryMap.get(key);
    }
    return this.markData.searchQueryMap;
  }

  @action
  getMarkData(key: string): any {
    return this.markData[key];
  }

  @action
  clearMarkData(): void {
    this.markData = {
      allOptList: {},
      complexSearch: new Map(),
      testAllOptList: new Map(),
      tempAllOptList: new Map()
    };
  }

  updateTempAllOptList(key: string, value: any): void {
    this.markData.tempAllOptList.set(key, value);
  }

  deleteTempAllOptList(key: string): void {
    this.markData.tempAllOptList.delete(key);
  }

  getTempAllOptList(key = ''): any {
    if (key) {
      return this.markData.tempAllOptList.get(key);
    }
    return this.markData.tempAllOptList;
  }
}
