/* eslint-disable quotes */
import { SHOW_NAME_REGEX, OUT_PUT_REGEX } from './regex';

const typeName = '文本编辑器组件';

export const TEXT_EDITOR_COMPONENT_SCHEMA = {
  type: 'object',
  default: {},
  title: '文本编辑器组件',
  required: [
    'outputWord',
    'internalExtension',
    'isFormatShowText',
    // 'supportLatex',
    'isRequired',
    'showName',
    'isShowName'
  ],
  properties: {
    outputWord: {
      type: 'string',
      default: '',
      title: '输出字段',
      pattern: OUT_PUT_REGEX,
      errorMessage: {
        pattern: `${typeName}【输出字段】内容错误，请修正`
      }
    },
    showPath: {
      type: 'number',
      default: 0,
      title: '是否展示入参路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示入参路径】内容错误，请修正`
      }
    },
    showName: {
      type: 'string',
      default: '',
      title: '组件名称',
      // pattern: SHOW_NAME_REGEX,
      errorMessage: {
        pattern: `${typeName}【组件名称】内容错误，请修正`
      }
    },
    isShowName: {
      type: 'number',
      default: 1,
      title: '是否展示组件名称',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示组件名称】内容错误，请修正`
      }
    },
    isFormatShowText: {
      type: 'number',
      title: '格式渲染',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【格式渲染】内容错误，请修正`
      }
    },
    internalExtension: {
      type: 'number',
      title: '段内小节'
    },
    supportLatex: {
      title: '公式编辑器',
      type: 'number'
    },
    isRequired: {
      title: '是否必填',
      type: 'number'
    }
  }
};

export default {};
