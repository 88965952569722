/* eslint-disable @typescript-eslint/no-extra-parens */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Layout, message, Modal, Radio, Select } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import './NavLayout.less';
import {
  addUserInfoToStorage,
  deleteUserInfoToStorage
} from '@/account/api/account';
import tldjs from 'tldjs';
import { debounce, throttle } from 'lodash';
import { getCookie, setCookie, delCookie } from '@/common/utils';
import { getUserInfo, checkoutPlatform } from '@/account/api/register';
import renderRoutes from '../route/renderRoutes';
import { RouteType } from '../route/index';
import UserInfo from '../account/component/UserInfoComponent';
import UserMenu from '../account/component/UserMenuComponent';
import UserValidate from '../account/component/UserValidateComponent';
import logo from '../account/assets/logo.png';
import utils from '../common/utils';
import { useStores } from '../hook';

const { Header, Content, Sider } = Layout;

const NavLayout: React.FC<{ routes: Array<RouteType> }> = ({ routes }) => {
  const { site, user } = useStores();
  const [selectList, setSelectList] = useState<any>([]);
  const [Comfort, setComfort] = useState<number>(0);
  const [OpenList, SetOpenList] = useState<any>([]);
  const [roleIdsArrayLength, setRoleIdsArrayLength] = useState(0);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const [Administrator, setAdministrator] = useState<any>();
  const [groupIdOpen, setGroupIdOpen] = useState<any>();
  const [selectedGroupId, setSelectedGroupId] = useState<any>([]);
  const [selectedRoute, setSelectedRoute] = useState<RouteType>();
  const [platforms, setPlatforms] = useState<string[]>([
    '管理平台',
    '标注平台',
    '验收平台'
  ]);
  const [menu, setMenu] = useState<string[]>([
    '管理平台',
    '标注平台',
    '验收平台'
  ]);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState<any>('');
  const history = useHistory();
  const [tagger, setTagger] = useState<any>({});
  const [examiner, setExaminer] = useState<any>({});
  const [roleCodes, setRoleCodes] = useState<any>([]);
  const [platformOpen, setPlatformOpen] = useState('Toggle');
  const [selectedPlatform, setSelectedPlatform] = useState('管理平台');
  const [rodeMarkSub, setRodeMarkSub] = useState<any>();
  const onClickSleet = (): void => {
    Daniel();
  };
  const handleSelectPlatform = (
    platform: React.SetStateAction<string>
  ): void => {
    setSelectedPlatform(platform);
  };
  const handleChange = debounce((value) => {
    setSelectedValue(value);
    setGroupIdOpen(value);
    const ttt = OpenList.find(
      (item: { groupId: any }) => item.groupId === value
    );
    setRoleCodes(ttt);
    setComfort(ttt.roleIds.length);
    performAction(Number(value), ttt.roleIds[0], 'LEFTOsTEP_INSPECT');
  }, 300);
  // 头像右侧的切换平台标志
  const handleClick = (): void => {
    const roleCodecs = localStorage.getItem('roleCode');
    switch (roleCodecs) {
      case 'COMMON_MARK':
        setSelectedButton('COMMON_MARK');
        break;
      case 'COMMON_INSPECT':
        setSelectedButton('COMMON_INSPECT');
        break;
      case 'GROUP_ADMIN':
        // 团队管理员
        setSelectedButton('GROUP_ADMIN');
        break;
      case 'GROUP_OWNER':
        setSelectedButton('GROUP_OWNER');
        break;
      default:
    }
    // 左侧平台切换高亮
    switch (roleCodecs) {
      case 'COMMON_INSPECT':
      case 'COMMON_MARK':
      case 'GROUP_ADMIN':
      case 'GROUP_OWNER':
        setSelectedPlatform('标注平台');
        break;
      case 'ALGORITHM':
        setSelectedPlatform('验收平台');
        break;
      case 'OPERATION':
      case 'OPERATION_COMMON':
      case 'OPERATION_COMMON_NORMAL':
        setSelectedPlatform('管理平台');
        break;
    }
  };
  useEffect(() => {
    handleClick();
  }, [roleCodes, user.roleCode]);
  const Daniel = (): any => {
    let userInfo = getCookie('userInfoOPEN');
    const domain = tldjs.getDomain(window.location.host);
    if (userInfo) {
      try {
        userInfo =
          typeof userInfo === 'string' ? JSON.parse(userInfo) : userInfo;
        addUserInfoToStorage(userInfo);
        // todo
        user.addUserFromStorage();
        delCookie('userInfoOPEN', '/', domain);
      } catch (error) {
        console.error('cookie处理报错', error);
      }
      delCookie('userInfoOPEN', '/', domain);
    } else {
      delCookie('userInfoOPEN', '/', domain);
    }
    getUserInfo().then((res) => {
      const values = Object.values(res.data?.usableClient);
      if (res && res.data.roleDetail && res.data.roleDetail.length > 0) {
        const ListBuhr = res.data.roleDetail.filter(
          (t: { roleIds: number[]; groupId: number; usableRole: any }) =>
            t.groupId > 0
        );
        setSelectList(
          ListBuhr.map((value: { groupName: any; groupId: any }) => ({
            label: value.groupName,
            value: value.groupId
          }))
        );
        SetOpenList(res.data.roleDetail);
      }
      const trueIndices: React.SetStateAction<string[]> = [];
      values.forEach((value, index) => {
        if (value) {
          trueIndices.push(menu[index]);
        }
      });
      setPlatforms(trueIndices);
      // 标注信息
      const filteredData = res.data.roleDetail.filter(
        (detail: { groupId: number; usableRole: Record<string, boolean> }) =>
          detail.groupId > 0 &&
          Object.values(detail.usableRole).includes(true) &&
          res.data.usableClient.labelPlatform
      );
      setRoleCodes(filteredData[0] || {});
      setGroupIdOpen(filteredData[0]?.groupId);
      setTagger(filteredData[0] || []);
      // 管理平台
      const AdminData = res.data.roleDetail.filter(
        (detail: {
          roleIds: number[];
          groupId: number;
          usableRole: Record<string, unknown>;
        }) =>
          detail.groupId === 0 &&
          res.data.usableClient.adminPlatform &&
          Object.values(detail.usableRole).every((value) => value === false) &&
          Array.isArray(detail.roleIds) &&
          (detail.roleIds.includes(2) ||
            detail.roleIds.includes(7) ||
            detail.roleIds.includes(8))
      );
      setAdministrator(AdminData[0] || {});

      const ExaminerData = res.data.roleDetail.filter(
        (detail: {
          groupId: number;
          usableRole: { [s: string]: unknown } | ArrayLike<unknown>;
          roleIds: number[];
        }) =>
          detail.groupId === 0 &&
          res.data.usableClient.clientPlatform &&
          Object.values(detail.usableRole).every((value) => value === false) &&
          Array.isArray(detail.roleIds) &&
          detail.roleIds.includes(3)
      );
      setExaminer(ExaminerData[0] || {});
      // setRoleCodes(res.data.roleDetail[0]);
    });
  };
  useEffect(() => {
    let roleIdsArray;
    if (Array.isArray(roleCodes.roleIds)) {
      roleIdsArray = roleCodes.roleIds;
    } else if (roleCodes.roleIds) {
      roleIdsArray = [roleCodes.roleIds];
    } else {
      roleIdsArray = [];
    }
    setSelectedGroupId(roleIdsArray);
    setRoleIdsArrayLength(roleIdsArray.length);
  }, [roleCodes]);
  useEffect(() => {
    Daniel();
    let currentRoute: undefined | RouteType;
    routes.forEach((routeItem) => {
      const path = routeItem.path.split(':')[0];
      if (location.pathname.includes(path)) {
        currentRoute = routeItem;
      }
    });
    if (currentRoute) {
      setSelectedRoute(currentRoute);
    }
  }, [history, location, routes]);
  const openMould = (): void => {
    Daniel();
    setIsModalOpen(true);
  };
  function performAction(
    groupId: number,
    roleId: number,
    style = 'LEFTOsTEP_INSPECTlist'
  ): void {
    const domain = tldjs.getDomain(window.location.host);
    checkoutPlatform({
      groupId,
      roleId,
      isCheck: 1
    })
      .then((res) => {
        const userInfo = JSON.stringify(res.data);
        // addUserInfoToStorage(res.data);
        let platform = '';
        switch (res.data.roleCode) {
          case 'COMMON_INSPECT':
          case 'COMMON_MARK':
          case 'GROUP_ADMIN':
          case 'GROUP_OWNER':
            platform = 'label';
            break;
          case 'ALGORITHM':
            platform = 'client';
            break;
          case 'OPERATION':
          case 'OPERATION_COMMON':
          case 'OPERATION_COMMON_NORMAL':
            platform = 'admin';
            break;
        }
        const host = window.location.host;
        const origin = window.location.origin;
        const port = window.location.port;
        let url = '';
        if (host.includes('facethink')) {
          if (host.includes('-pre')) {
            url = `https://${platform}-pre.vegas.facethink.com`;
          } else {
            url = `https://${platform}.vegas.facethink.com`;
          }
        } else if (host.includes('test.datasmarker')) {
          url = `https://${platform}.test.datasmarker.com`;
        } else if (host.includes('-pre.datasmarker')) {
          url = `https://${platform}-pre.datasmarker.com`;
        } else if (host.includes('datasmarker')) {
          url = `https://${platform}.datasmarker.com`;
        } else if (host.includes('100tal')) {
          if (host.includes('-pre')) {
            url = `https://${platform}-pre.vegas.100tal.com`;
          } else {
            url = `https://${platform}.vegas.100tal.com`;
          }
        } else if (host.includes('localhost')) {
          url = `http://localhost:${port}`;
        }
        setCookie('userInfoOPEN', userInfo, 1, '/', domain);
        handleClick();
        // 切换角色时，清除用户信息
        deleteUserInfoToStorage();
        user.addUserFromStorage();
        // localStorage.setItem('registerSource', res.data.registerSource);
        switch (roleId) {
          case 6:
            window.location.href = `${url}/annotation/mark-list`;
            break;
          case 9:
            window.location.href = `${url}/annotation/inspect-list`;
            break;
          case 7:
          case 2:
            // 运营人员
            window.location.href = `${url}/project/list`;
            break;
          case 3:
            // 验收人员
            window.location.href = `${url}/project/list`;
            break;
          case 4:
            // 团队负责人
            window.location.href = `${url}/project/list`;
            break;
          case 5:
          case 8:
            // 团队管理员
            window.location.href = `${url}/project/list`;
            break;
          default:
        }
        // if (style && style !== 'LEFTOsTEP_INSPECT') {
        // }
      })
      .catch((error) => {
        console.error('Error during platform checkout:', error);
        // Optionally, show an error message to the user
      });
  }
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryValue = urlParams.get('query');
    if (queryValue && selectList.length > 0) {
      const ttt = selectList.filter(
        (item: { value: number }) => item.value === Number(queryValue)
      );
      setSelectedValue(ttt[0].value);
    }
    if (selectList.length > 0 && !queryValue) {
      setSelectedValue(selectList[0].value);
    }
  }, [selectList]);

  const throttledPerformAction = throttle((groupIdOpenstr, roleId, type) => {
    performAction(groupIdOpenstr, roleId, type);
  }, 1000);
  const handleCheckoutPlatform = (
    item: string,
    path: string,
    roleId: number,
    type: string
  ): void => {
    setSelectedButton(item);
    // setRodeMarkSub(roleId);
    throttledPerformAction(groupIdOpen, roleId, type);
  };
  const containsRoleCode = (roleCodesOpen: any, targetRoleCode: any): any => {
    return roleCodesOpen.roleIds?.some(
      (Open: { roleCode: any }) => Open === targetRoleCode
    );
  };

  const handleOk = (): void => {
    setIsModalOpen(false);
    setPlatformOpen('Successful');
    if (selectedPlatform === '管理平台') {
      performAction(
        Administrator.groupId,
        Administrator.roleIds.filter((t: number) => t !== 3)[0]
      );
    } else if (selectedPlatform === '标注平台') {
      performAction(tagger.groupId, tagger?.roleIds[0]);
    } else {
      performAction(examiner.groupId, 3);
    }
  };
  const handleCancel = (): void => {
    setIsModalOpen(false);
    handleClick();
  };
  return (
    <div>
      <UserValidate route={selectedRoute} />
      <Layout style={{ overflow: 'hidden' }}>
        <Sider
          style={{
            overflow: 'auto',
            display: 'flex',
            height: '100vh',
            position: 'fixed',
            left: 0
          }}
        >
          <div className="logo">
            {site.isOrder || site.name === '' ? (
              ''
            ) : (
              <img
                src={logo}
                alt="logo"
                style={{ width: '31px', height: '24px' }}
              />
            )}
            {site.name === '' ? (
              ''
            ) : (
              <div className="title">
                {site.name}
                {utils.getPlatformName()}
                平台
                <span
                  onClick={openMould}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    padding: 0
                  }}
                >
                  <img
                    src="/icon/role.png"
                    alt="logo"
                    style={{ width: '20px', height: '20px' }}
                  />
                </span>
              </div>
            )}
          </div>
          <UserMenu route={selectedRoute} />
          {user.roleCode != 'OPERATION_COMMON_NORMAL' && (
            <div className="extra-link-area">
              <a
                href={
                  window.location.host.indexOf('vegas') !== -1
                    ? 'https://ai-assistant.vegas.100tal.com/'
                    : 'https://ai-assistant.datasmarker.com/'
                }
                target="_blank"
                rel="noreferrer"
              >
                AI对话助手
              </a>
              <br />
              <a
                href={
                  window.location.host.indexOf('vegas') !== -1
                    ? 'https://math-tool.vegas.100tal.com'
                    : 'https://math-tool.datasmarker.com'
                }
                target="_blank"
                rel="noreferrer"
              >
                数学工具助手
              </a>
              <br />
              <a href="/latex/converter" target="_blank" rel="noreferrer">
                Latex格式化工具
              </a>
              <br />
              <a
                href={
                  window.location.host.indexOf('vegas') !== -1
                    ? 'https://json-tool.vegas.100tal.com'
                    : 'https://json-tool.datasmarker.com'
                }
                target="_blank"
                rel="noreferrer"
              >
                Json工具助手
              </a>
            </div>
          )}
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 200 }}>
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              height: '60px',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* 曹帅帅 修改标注头像右侧平台切换 */}
              {(user.roleCode === 'COMMON_MARK' ||
                user.roleCode === 'COMMON_INSPECT' ||
                user.roleCode === 'GROUP_OWNER' ||
                user.roleCode === 'GROUP_ADMIN') &&
                selectList.length >= 1 && (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Select
                      style={{ width: 120, marginRight: '10px', borderRadius: '2px' }}
                      onChange={handleChange}
                      options={selectList}
                      onClick={onClickSleet}
                      value={selectedValue}
                    />
                    {roleIdsArrayLength > 1 && (
                      <div>
                        {(roleCodes.usableRole?.groupManager ||
                          selectList[0]?.usableRole?.groupManager) &&
                          selectedGroupId.includes(5) && (
                            <Button
                              type={
                                selectedButton === 'GROUP_ADMIN'
                                  ? 'primary'
                                  : 'default'
                              }
                              style={{
                                width: '130px',
                                whiteSpace: 'pre-wrap',
                                borderRadius: '0px'
                              }}
                              onClick={() =>
                                handleCheckoutPlatform(
                                  'GROUP_ADMIN',
                                  '/project/list',
                                  5,
                                  'LEFTOsTEP_INSPECT'
                                )
                              }
                            >
                              团队管理员
                            </Button>
                          )}
                        {(roleCodes.usableRole?.groupManager ||
                          selectList[0]?.usableRole?.groupManager) &&
                          selectedGroupId.includes(4) && (
                            <Button
                              type={
                                selectedButton === 'GROUP_OWNER'
                                  ? 'primary'
                                  : 'default'
                              }
                              style={{
                                width: '130px',
                                whiteSpace: 'pre-wrap',
                                borderRadius: '2px'
                              }}
                              onClick={() =>
                                handleCheckoutPlatform(
                                  'GROUP_OWNER',
                                  '/project/list',
                                  4,
                                  'LEFTOsTEP_INSPECT'
                                )
                              }
                            >
                              团队负责人
                            </Button>
                          )}
                        {(roleCodes.usableRole?.labelManager ||
                          selectList[0]?.usableRole?.labelManager) && (
                          <Button
                            type={
                              selectedButton === 'COMMON_MARK'
                                ? 'primary'
                                : 'default'
                            }
                            style={{
                              width: '130px',
                              whiteSpace: 'pre-wrap',
                              borderRadius: '2px'
                            }}
                            onClick={() =>
                              handleCheckoutPlatform(
                                'COMMON_MARK',
                                '/annotation/mark-list',
                                6,
                                'LEFTOsTEP_INSPECT'
                              )
                            }
                          >
                            团队成员-标注
                          </Button>
                        )}
                        {(roleCodes.usableRole?.inspectManager ||
                          selectList[0]?.usableRole?.inspectManager) && (
                          <Button
                            type={
                              selectedButton === 'COMMON_INSPECT'
                                ? 'primary'
                                : 'default'
                            }
                            style={{
                              width: '130px',
                              whiteSpace: 'pre-wrap',
                              borderRadius: '2px'
                            }}
                            onClick={() =>
                              handleCheckoutPlatform(
                                'COMMON_INSPECT',
                                '/annotation/inspect-list',
                                9,
                                'LEFTOsTEP_INSPECT'
                              )
                            }
                          >
                            团队成员-质检
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              <UserInfo />
            </div>
          </Header>
          <Content
            style={{
              overflow: 'initial',
              backgroundColor: '#fff',
              borderTop: '1px solid #EAEAEA',
              boxShadow: '0 0 2px #EAEAEA',
              padding: '18px 9px 20px 9px',
              color: '#333333'
            }}
          >
            {routes && renderRoutes(routes)}
          </Content>
        </Layout>
      </Layout>
      <Modal
        title={<div style={{ textAlign: 'center' }}>切换平台</div>}
        open={isModalOpen}
        onOk={handleOk}
        width="200px"
        closable={false}
        onCancel={handleCancel}
        wrapClassName="modalClass"
        style={{
          top: 10,
          left: '235px',
          margin: 0,
          height: '204px',
          position: 'absolute',
          padding: '0px'
        }}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
            style={{
              marginRight: '29px',
              borderRadius: '4px',
              color: '#508AED',
              // marginTop: '-8px'
            }}
          >
            取消
          </Button>,
          <Button
            key="ok"
            type="primary"
            onClick={handleOk}
            style={{ borderRadius: '4px' }}
          >
            确认
          </Button>
        ]}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // marginTop: '-13px'
          }}
        >
          {platforms.map((platform, index) => (
            <Button
              key={`${platform}-${index - 1}`}
              style={{
                width: '168px',
                borderRadius: '4px',
                marginBottom: '12px',
                borderColor:
                  selectedPlatform === platform ? '#1890ff' : undefined,
                color: selectedPlatform === platform ? '#1890ff' : undefined
              }}
              onClick={() => handleSelectPlatform(platform)}
            >
              {platform}
            </Button>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default NavLayout;
