import React, { FC, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { ErrorBoundary } from 'react-error-boundary';
import Aegis from 'aegis-web-sdk';
import './App.less';
import index from './route/index';
import renderRoutes from './route/renderRoutes';
import stores from './store/index';
import { useStores } from './hook';

const App: FC = () => {
  const { site } = useStores();
  useEffect(() => {
    site.updateHost();
    let isProd = false;
    const banControlUrlArray = [
      'admin.vegas.100tal.com',
      'label.vegas.100tal.com',
      'client.vegas.100tal.com',
      'admin.datasmarker.com',
      'label.datasmarker.com',
      'client.datasmarker.com',
      'label-datasmarker.facethink.com'
      // 'label.vegas.facethink.com',
      // 'admin.vegas.facethink.com',
      // 'client.vegas.facethink.com'
    ];

    if (window.location.host !== 'localhost:9988') {
      const reportUin = banControlUrlArray.includes(window.location.host)
        ? 'online'
        : 'test';
      const aegis = new Aegis({
        id: 'wlDbmTzZQ0ZqygJyLg', // 上报 id
        uin: reportUin, // 用户唯一 ID（可选）
        reportApiSpeed: true, // 接口测速
        reportAssetSpeed: true, // 静态资源测速
        spa: true, // spa 应用页面跳转的时候开启 pv 计算
        // hostUrl: 'https://rumt-zh.com',
        api: {
          // 接口入参和出参的详情
          apiDetail: true,
          // 自定义状态码异常的上报钩子
          retCodeHandler(data: any) {
            // 注意这里拿到的data是string类型，如果需要对象需要手动parse下
            try {
              data = JSON.parse(data);
            } catch (e) {
              console.warn('解析data报错', e);
            }
            return {
              // isErr 如果是 true 的话，会上报一条 retcode 异常的日志。
              // 参考文档 https://cloud.tencent.com/document/product/248/87197
              // https://wiki.zhiyinlou.com/pages/viewpage.action?pageId=10646020
              isErr: data.code !== 20000,
              code: data.code
            };
          }
        },
        beforeReport(log: any) {
          console.warn('log', log);
          if (
            log.level === '4' &&
            log.msg &&
            log.msg.indexOf('ckeditor.js') !== -1
          ) {
            return false;
          }
          return log;
        }
      });
      // @ts-ignore
      window.$Aegis = aegis;
    }

    if (banControlUrlArray.includes(window.location.host)) {
      const hashCode = window.location.hash;
      if (hashCode === '#m=1') {
        window.localStorage.setItem('devMode', 'dev');
      }
      const devMode = window.localStorage.getItem('devMode') || '';
      if (devMode !== 'dev') {
        isProd = true;
      }
    }

    if (!isProd) return;
    setInterval(() => {
      console.log(
        Object.defineProperties(new Error(), {
          message: {
            // eslint-disable-next-line getter-return
            get() {
              window.location.href = 'about:blank';
            }
          },
          toString: {
            value() {
              new Error().stack?.includes('toString@') && alert('Safari');
            }
          }
        })
      );
    }, 2000);
    // eslint-disable-next-line
    document.addEventListener('keydown', (e) => {
      if (e.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
        return false;
      }
    });
  }, []);
  return (
    <div className="App">
      <Provider {...stores}>
        {/* <ErrorBoundary
          FallbackComponent={({ error, resetErrorBoundary }: any) => {
            return (
              <div>
                Something went wrong, {error.message}
                <button onClick={resetErrorBoundary}>重试</button>
              </div>
            );
          }}
        > */}
        <BrowserRouter>{renderRoutes(index)}</BrowserRouter>
        {/* </ErrorBoundary> */}
      </Provider>
    </div>
  );
};

export default App;
