import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Image, Button, Card, message } from 'antd';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useStores } from '@/hook';
import { platform } from '../../common/config';
import './ChangePsd.less';
import { deleteUserInfoToStorage, fetchCaptcha } from '../api/account';
import { getSMSCode, modifyPassword, modifyPhone } from '../api/register';
import utils from '../../common/utils';

/**
 * (?=.*[0-9]) 必须包含数字
 * (?=.*[a-z])(?=.*[A-Z])必须包含大小写英文
 * (?=.*[\!\@\#\$\%\^\&\*\(\)\_\+\=\{\}\[\]\;\:\'\"\,\.\<\>\?\|\/\-]) 必须包含特殊字符
 * ^.{8,16} 总长度在8-16之间
 */
const passwordRegexp =
  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\!\@\#\$\%\^\&\*\(\)\_\+\=\{\}\[\]\;\:\'\"\,\.\<\>\?\|\/\-])^.{8,16}$/;

const ChangePsd: React.FC<any> = (props: any) => {
  const { site } = useStores();
  const { title, clearAll, type } = props;
  const [captchaImage, setCaptchaImage] = useState('');
  const history = useHistory();
  const [password, setPassword] = useState<string>('');
  const [hasPhone, setHasPhone] = useState<any>('');
  const [form] = Form.useForm();

  const handleFinish = (values: any): void => {
    const sendParams = _.cloneDeep(values);
    delete sendParams.rePassword;
    if (type === 'password') {
      modifyPassword(sendParams)
        .then((res: any) => {
          if (res.code === 20000) {
            props.initCallback(true);
          }
        })
        .then((err) => {
          console.warn('err', err);
        });
    } else if (type === 'phone') {
      sendParams.oldPhone = sendParams.phone;
      delete sendParams.phone;
      modifyPhone(sendParams).then((res: any) => {
        if (res.code === 20000) {
          props.initCallback(true);
        } else {
          message.error(res.msg);
        }
      });
    } else if (type === 'weakPsd') {
      sendParams.captcha = '1';
      sendParams.smsCode = '1';
      sendParams.source = 'weakCipher';
      modifyPassword(sendParams)
        .then((res: any) => {
          if (res.code === 20000) {
            props.initCallback(true);
          }
        })
        .then((err) => {
          console.warn('err', err);
        });
    }
  };
  const handleChangePassword = useCallback((e) => {
    setPassword(e.target.value);
  }, []);
  const handleFetchCaptcha = useCallback(() => {
    fetchCaptcha().then(({ data }) => {
      setCaptchaImage(`data:image/jpeg;base64,${data as string}`);
    });
  }, []);
  const handleGetSMSCode = useCallback(() => {
    const captcha = form.getFieldValue('captcha');
    const phone =
      type === 'phone'
        ? form.getFieldValue('newPhone')
        : form.getFieldValue('phone');
    const source = site.siteParams;
    if (!captcha || !phone) {
      return;
    }
    getSMSCode({
      captcha,
      phone,
      smsType: 'password',
      source
    })
      .then((res) => {
        const result: any = res;
        if (result.code === 20000) {
          utils.messageInfo({
            message: '短信发送成功，请查收'
          });
        }
      })
      .catch((error: any) => {
        // 图形验证码错误，请重新输入， 此时需要刷新图形验证码
        if (error.code === 601017) {
          handleFetchCaptcha();
        }
      });
  }, [form, handleFetchCaptcha]);
  useEffect(() => {
    if (localStorage.getItem('phone') || localStorage.getItem('username')) {
      setHasPhone(
        localStorage.getItem('phone') || localStorage.getItem('username')
      );
      form.setFieldsValue({
        phone: localStorage.getItem('username') || localStorage.getItem('phone')
      });
      if (clearAll) {
        deleteUserInfoToStorage();
      }
    }
    handleFetchCaptcha();
  }, [history, handleFetchCaptcha]);

  return (
    <div className="change-psd-container" style={{ width: '600px' }}>
      <Card title={title} className="register-form">
        <Form
          name="register"
          initialValues={{ remember: true }}
          validateTrigger="onBlur"
          onFinish={handleFinish}
          form={form}
        >
          <Form.Item
            label={type === 'phone' ? '现手机号' : '手机号'}
            name="phone"
            rules={[
              { required: true, message: '请输入手机号' },
              { pattern: /^1\d{10}$/g, message: '请输入正确的手机号码' }
            ]}
          >
            {hasPhone ? (
              <Input disabled className="register-item-input" />
            ) : (
              <Input
                placeholder="请输入手机号"
                autoComplete="off"
                className="register-item-input"
              />
            )}
          </Form.Item>
          {type === 'phone' && (
            <Form.Item
              label="新手机号"
              name="newPhone"
              rules={[
                { required: true, message: '请输入手机号' },
                { pattern: /^1\d{10}$/g, message: '请输入正确的手机号码' }
              ]}
            >
              <Input
                placeholder="请输入手机号"
                autoComplete="off"
                className="register-item-input"
              />
            </Form.Item>
          )}
          {(type === 'phone' || type === 'password') && (
            <>
              <Form.Item label="图形验证码" className="captcha">
                <Form.Item
                  name="captcha"
                  rules={[
                    { required: true, message: '请输入正确的验证码' },
                    { len: 4, message: '请输入正确的验证码' }
                  ]}
                  noStyle
                >
                  <Input
                    style={{ width: '140px' }}
                    placeholder="请输入图形验证码"
                    maxLength={4}
                    autoComplete="off"
                    className="register-item-input"
                  />
                </Form.Item>
                <Image
                  onClick={handleFetchCaptcha}
                  width={120}
                  height={32}
                  style={{ marginLeft: '10px', verticalAlign: 'bottom' }}
                  src={captchaImage}
                  preview={false}
                />
              </Form.Item>
              <Form.Item label="短信验证码" className="smsCode">
                <Form.Item
                  name="smsCode"
                  rules={[
                    { required: true, message: '请输入短信验证码' },
                    { len: 6, message: '请输入短信验证码' }
                  ]}
                  noStyle
                >
                  <Input
                    style={{ width: '140px', marginRight: '10px' }}
                    placeholder="请输入短信验证码"
                    maxLength={6}
                    autoComplete="off"
                    className="register-item-input"
                  />
                </Form.Item>
                <Button size="small" type="primary" onClick={handleGetSMSCode}>
                  获取短信验证码
                </Button>
              </Form.Item>
            </>
          )}
          <Form.Item
            label="登录密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入8-16位字符，需包含大小写英文，数字，以及符号'
              },
              {
                pattern: passwordRegexp,
                message: '请输入8-16位字符，需包含大小写英文，数字，以及符号'
              }
            ]}
          >
            <Input.Password
              placeholder="请输入8-16位字符，需包含大小写英文，数字，以及符号"
              minLength={6}
              maxLength={16}
              onChange={handleChangePassword}
              autoComplete="new-password"
              className="new-password"
            />
          </Form.Item>
          {(type === 'password' || type === 'weakPsd') && (
            <Form.Item
              label="确认密码"
              name="rePassword"
              rules={[
                {
                  validator(rule, value) {
                    if (!value) {
                      return Promise.reject(new Error('请再次输入密码'));
                    }
                    if (!passwordRegexp.test(value)) {
                      return Promise.reject(
                        new Error(
                          '请输入8-16位字符，需包含大小写英文，数字，以及符号'
                        )
                      );
                    }
                    if (value !== password) {
                      return Promise.reject(
                        new Error('确认密码与密码不一致，请重新输入')
                      );
                    }
                    return Promise.resolve();
                  }
                }
              ]}
              className="rePassword"
            >
              <Input.Password
                placeholder="请再次输入密码"
                minLength={6}
                maxLength={16}
                autoComplete="new-password"
                className="new-password"
              />
            </Form.Item>
          )}
          <Form.Item className="buttons">
            <Button
              type="primary"
              htmlType="submit"
              block
              style={{ width: '300px' }}
            >
              确认修改
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
export default ChangePsd;
