/* eslint-disable quotes */
import { SHOW_NAME_REGEX, OUT_PUT_REGEX } from './regex';

const typeName = '单选';
export const RADIO_COMPONENT_SCHEMA = {
  $schema: 'http://json-schema.org/draft-07/schema#',
  type: 'object',
  title: '单选',
  required: [
    'showPath',
    'outputWord',
    'isRequired',
    'optionSource',
    'showName',
    'isShowName'
  ],
  properties: {
    jsonPath: {
      type: 'string',
      default: '',
      title: '输入路径',
      errorMessage: {
        pattern: `${typeName}【输入路径】内容错误，请修正`
      }
    },
    showPath: {
      type: 'number',
      default: 0,
      title: '是否展示入参路径',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示入参路径】内容错误，请修正`
      }
    },
    showName: {
      type: 'string',
      title: '组件名称',
      // pattern: SHOW_NAME_REGEX,
      errorMessage: `${typeName}, 组件名称内容不符合要求`
    },
    isShowName: {
      type: 'number',
      default: 1,
      title: '是否展示组件名称',
      pattern: '.+',
      errorMessage: {
        pattern: `${typeName}【是否展示组件名称】内容错误，请修正`
      }
    },
    outputWord: {
      type: 'string',
      default: '',
      title: '输出字段',
      pattern: OUT_PUT_REGEX,
      errorMessage: {
        pattern: `${typeName}【输出字段】内容错误，请修正`
      }
    },
    isRequired: {
      type: 'number',
      default: 0,
      title: '是否必填'
    },
    optionSource: {
      type: 'string',
      default: 'fixed',
      title: '选项来源'
    }
  }
};

export default {};
